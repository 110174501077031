.modal__sub {
  font-weight: 700;
  padding-bottom: 16px;
}

.modal__content {
    text-align: left;
}

.modal__content > p {
    font-weight: 300;
    margin-top: 8px;
}