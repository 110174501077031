@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400);
.modal__sub {
  font-weight: 700;
  padding-bottom: 16px;
}

.modal__content {
    text-align: left;
}

.modal__content > p {
    font-weight: 300;
    margin-top: 8px;
}
.truncate {
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.ant-btn .anticon.down {
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
}

.down--rotated {
  -webkit-transform: rotate(-180deg) translateY(3px);
          transform: rotate(-180deg) translateY(3px);
}

.sortableItem {
	margin-top: 5px;
	margin-bottom: 0px;
	cursor: move;
	padding: 0.5em;
	border-radius: 8px;
	background-color: white;
}

.attribute {
	padding: 0.5em 0.5em 0.5em 0em;
}

.settings {
	padding: 0 3em;
}

.settings .color {
	display: grid;
	grid-template-columns: 1fr 3fr;
	-webkit-align-items: center;
	        align-items: center;
	padding: 0.5em 0;
}

.settings .alias {
	display: grid;
	grid-template-columns: 1fr 1fr;
	-webkit-align-items: center;
	        align-items: center;
	padding: 16px 0px;
}
.settings .alias > div:nth-child(2) {
	width: 100%;
	/* margin-left: 100px; */
	/* padding-right: 36px; */

}

hr {
	border: 0;
	margin: 0;
	padding: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.scheme10 {
	display: grid;
	grid-template-columns: repeat(10, 2fr);
}

.scheme20 {
	display: grid;
	grid-template-columns: repeat(20, 2fr);
}

.sequential-picker {
	/*border: 1px solid black;*/
	display: grid;
	grid-template-columns: repeat(6, 1fr);

}
.sequential-picker > * {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	justify-self: center;
}

.example-component {
  width: 100px;
  height: 100px;
  background-color: red;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.example-component.show {
  opacity: 1;
  visibility: visible;
}

.box {
  visibility: visible;
  height: 90px;
  width: 100%;
  opacity: 1.0;
  box-sizing: border-box;
  transition: min-height 500ms 0ms, height 500ms 500ms, opacity 500ms 500ms, visibility 500ms 500ms;
}

.box.hide {
  transition: min-height 500ms 500ms, height 500ms 500ms, opacity 500ms 0ms, visibility 500ms 500ms;
  opacity: 0.0;
  visibility: hidden;
  height: 0px;
}

.sidebar {
  opacity: 1.0;
  box-sizing: border-box;
  transition: opacity 500ms, -webkit-transform 500ms 500ms;
  transition: opacity 500ms, transform 500ms 500ms;
  transition: opacity 500ms, transform 500ms 500ms, -webkit-transform 500ms 500ms;
  margin: 0.5em;
  height: 80vh;
  overflow-y: scroll;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  width: 100%;
}

.sidebar.hide {
  opacity: 0;
  -webkit-transform: translateX(-90%);
          transform: translateX(-90%);
}

.vis.vis--hide {
  width: 3000%;
}

.vis {
  width: 100%;
  overflow-x: scroll;
  min-height: 70vh;
  transition: width 500ms;
}

.container-vis {
  display: -webkit-flex;
  display: flex;
}
.loader--show {
  height: 100vh;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.loader--hide {
  display: none;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
body {
	line-height: 1;
	min-height: 100vh;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html {
	font-family: 'Roboto', sans-serif;
}
.hoverable {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.hoverable:hover {
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.ant-table td {
	word-break: break-word;
}
