.sortableItem {
	margin-top: 5px;
	margin-bottom: 0px;
	cursor: move;
	padding: 0.5em;
	border-radius: 8px;
	background-color: white;
}

.attribute {
	padding: 0.5em 0.5em 0.5em 0em;
}

.settings {
	padding: 0 3em;
}

.settings .color {
	display: grid;
	grid-template-columns: 1fr 3fr;
	align-items: center;
	padding: 0.5em 0;
}

.settings .alias {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	padding: 16px 0px;
}
.settings .alias > div:nth-child(2) {
	width: 100%;
	/* margin-left: 100px; */
	/* padding-right: 36px; */

}

hr {
	border: 0;
	margin: 0;
	padding: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.scheme10 {
	display: grid;
	grid-template-columns: repeat(10, 2fr);
}

.scheme20 {
	display: grid;
	grid-template-columns: repeat(20, 2fr);
}

.sequential-picker {
	/*border: 1px solid black;*/
	display: grid;
	grid-template-columns: repeat(6, 1fr);

}
.sequential-picker > * {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	justify-self: center;
}
