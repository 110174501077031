.loader--show {
  height: 100vh;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader--hide {
  display: none;
}