.sidebar {
  opacity: 1.0;
  box-sizing: border-box;
  transition: opacity 500ms, transform 500ms 500ms;
  margin: 0.5em;
  height: 80vh;
  overflow-y: scroll;
  transform: translateX(0%);
  width: 100%;
}

.sidebar.hide {
  opacity: 0;
  transform: translateX(-90%);
}

.vis.vis--hide {
  width: 3000%;
}

.vis {
  width: 100%;
  overflow-x: scroll;
  min-height: 70vh;
  transition: width 500ms;
}

.container-vis {
  display: flex;
}